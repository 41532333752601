<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal" 
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <div class="p-6">
       <vs-checkbox class="mt-5" v-model="IsOnlineAppointmentNotification">{{ $t("OnlineAppointment")}} </vs-checkbox>
        <vs-checkbox class="mt-5" v-model="IsSurgeryDateNotification"> {{ $t("SurgeryDate")}} </vs-checkbox>
        <vs-checkbox class="mt-5" v-model="IsOnlineAppointmentpaymentIsDue">{{ $t("OnlineAppointmentpayment")}} </vs-checkbox>
        <vs-checkbox class="mt-5" v-model="IsSurgeryPaymentDue"> {{ $t("SurgeryPayment")}} </vs-checkbox>
        <vs-checkbox class="mt-5" v-model="IsReceiveOffersAndPromotions">{{ $t("ReceiveOffersAndPromotions")}} </vs-checkbox>
        <vs-checkbox class="mt-5" v-model="IsReceiveMonthlyNewsletter"> {{ $t("ReceiveMonthlyNewsletter")}} </vs-checkbox>
        <vs-checkbox class="mt-5" v-model="IsReceiveInformationPackages">{{ $t("ReceiveInformationPackages")}} </vs-checkbox>
        <vs-checkbox class="mt-5" v-model="IsReceiveInformationRecentlyAddedHospitalsAndDoctors"> {{ $t("ReceiveInformationRecentlyAddedHospitalsAndDoctors")}} </vs-checkbox>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">{{
        $t("Save")
      }}</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain } from "@/gloabelConstant.js";
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { ID,UserID,IsOnlineAppointmentNotification,
        IsSurgeryDateNotification,
        IsOnlineAppointmentpaymentIsDue,
        IsSurgeryPaymentDue,
        IsReceiveOffersAndPromotions,
        IsReceiveMonthlyNewsletter,
        IsReceiveInformationPackages,
        IsReceiveInformationRecentlyAddedHospitalsAndDoctors } = JSON.parse(JSON.stringify(this.data));
        this.ID = ID;
        this.UserID = UserID;
        this.IsOnlineAppointmentNotification = IsOnlineAppointmentNotification;
        this.IsSurgeryDateNotification = IsSurgeryDateNotification;
        this.IsOnlineAppointmentpaymentIsDue = IsOnlineAppointmentpaymentIsDue;
        this.IsSurgeryPaymentDue = IsSurgeryPaymentDue;
        this.IsReceiveOffersAndPromotions = IsReceiveOffersAndPromotions;
        this.IsReceiveMonthlyNewsletter = IsReceiveMonthlyNewsletter;
        this.IsReceiveInformationPackages = IsReceiveInformationPackages;
        this.IsReceiveInformationRecentlyAddedHospitalsAndDoctors = IsReceiveInformationRecentlyAddedHospitalsAndDoctors;

      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.ID, this.Name, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },

  data() {
    return {
      baseURL: domain,
      uploadUrl: domain + "API/Common/UploadFile",
      ID: null,
      NameEN: "",
      NameAR: "",
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.NameEN;
    }
  },

  methods: {
    initValues() {
      if (this.data.ID) return;
      this.ID = null;
       this.UserID = null;
        this.IsOnlineAppointmentNotification = false;
        this.IsSurgeryDateNotification = false;
        this.IsOnlineAppointmentpaymentIsDue = false;
        this.IsSurgeryPaymentDue = false;
        this.IsReceiveOffersAndPromotions = false;
        this.IsReceiveMonthlyNewsletter = false;
        this.IsReceiveInformationPackages = false;
        this.IsReceiveInformationRecentlyAddedHospitalsAndDoctors = false;
    },

    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            ID: this.ID,
            UserID : this.UserID,
        IsOnlineAppointmentNotification :this.IsOnlineAppointmentNotification,
        IsSurgeryDateNotification : this.IsSurgeryDateNotification,
       IsOnlineAppointmentpaymentIsDue : this.IsOnlineAppointmentpaymentIsDue,
       IsSurgeryPaymentDue : this.IsSurgeryPaymentDue,
       IsReceiveOffersAndPromotions : this.IsReceiveOffersAndPromotions,
        IsReceiveMonthlyNewsletter : this.IsReceiveMonthlyNewsletter,
        IsReceiveInformationPackages : this.IsReceiveInformationPackages,
        IsReceiveInformationRecentlyAddedHospitalsAndDoctors : this.IsReceiveInformationRecentlyAddedHospitalsAndDoctors,

          };

          if (this.ID !== null && this.ID >= 0) {
            this.$store
              .dispatch("UserNotificationSettingList/UpdateUserNotificationSetting", obj)
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          } else {
            delete obj.ID;
            obj.popularity = 0;
            this.$store.dispatch("UserNotificationSettingList/AddUserNotificationSetting", obj).catch(err => {
              console.error(err);
              window.showError(err.response.data.message);
            });
          }

          this.$emit("closeSidebar");
          this.initValues();
          //this.$store.dispatch("foodTypeList/fetchfoodTypeListItems");
        }
      });
    }
  },

  components: {
    VuePerfectScrollbar
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
