/*=========================================================================================
  File Name: moduleUserNotificationSettingMutations.js
  Description: UserNotificationSetting Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.UserNotificationSettings.unshift(item)
  },
  SET_UserNotificationSetting(state, UserNotificationSettings) {
    state.UserNotificationSettings = UserNotificationSettings
  },
  UPDATE_UserNotificationSetting(state, UserNotificationSetting) {
    const UserNotificationSettingIndex = state.UserNotificationSettings.findIndex((p) => p.ID == UserNotificationSetting.ID)
    Object.assign(state.UserNotificationSettings[UserNotificationSettingIndex], UserNotificationSetting)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.UserNotificationSettings.findIndex((p) => p.ID == itemId)
    state.UserNotificationSettings.splice(ItemIndex, 1)
},
}
